import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    ),
                },
                {
                    path: 'register',
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    ),
                },
                { path: 'login-unprotected', element: <Login /> },
                { path: 'register-unprotected', element: <Register /> },
                { path: 'reset-password', element: <ResetPassword /> },
                { path: 'verify', element: <VerifyCode /> },
            ],
        },

        // Dashboard Routes
        {
            path: '/',
            element: (
                <AuthGuard>
                    <RoleBasedGuard>
                        <DashboardLayout />
                    </RoleBasedGuard>
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                { path: 'dashboard', element: <GeneralApp /> },
                {
                    path: 'profile',
                    children: [{ path: 'edit-profile', element: <UserAccount /> }],
                },
                {
                    path: 'user-management',
                    children: [
                        { path: 'roles', element: <Roles /> },
                        { path: 'role/:id/permission', element: <RolePermission /> },
                        { path: 'role/add', element: <RoleOperation /> },
                        { path: 'role/:id/edit', element: <RoleOperation /> },
                        { path: 'role/:id/view', element: <RoleOperation /> },
                        { path: 'users', element: <Employees /> },
                        { path: 'user/add', element: <EmployeeOperation /> },
                        { path: 'user/:id/edit', element: <EmployeeOperation /> },
                        { path: 'user/:id/view', element: <EmployeeOperation /> },
                        { path: 'user-approval', element: <UserApproval /> },
                        { path: 'user-approval/:id/edit', element: <UserApprovalOperation /> },
                    ],
                },
                {
                    path: 'user-task',
                    children: [
                        { path: '', element: <UserTasks /> },
                        { path: ':id/view', element: <UserTaskOperation /> },
                        { path: ':id/edit', element: <UserTaskOperation /> },
                        { path: 'add', element: <UserTaskOperation /> },
                    ],
                },
                {
                    path: 'subscription',
                    children: [
                        { path: 'parents', element: <Parents /> },
                        { path: 'parent/:id/view', element: <ParentOperation /> },
                        { path: 'students', element: <Students /> },
                        { path: 'student/:id/view', element: <StudentOperation /> },
                        { path: 'transaction', element: <Transaction /> },
                        { path: 'transaction/:id/view', element: <TransactionOperation /> },
                    ],
                },
                {
                    path: 'master',
                    children: [
                        { path: 'learnings', element: <GeneralApp /> },
                        { path: 'levels', element: <Levels /> },
                        { path: 'level/add', element: <LevelOperation /> },
                        { path: 'level/:id/edit', element: <LevelOperation /> },
                        { path: 'level/:id/view', element: <LevelOperation /> },
                        { path: 'grades', element: <Grades /> },
                        { path: 'grade/:id/edit', element: <GradeOperation /> },
                        { path: 'grade/:id/view', element: <GradeOperation /> },
                        { path: 'grade/add', element: <GradeOperation /> },
                        { path: 'languages', element: <Languages /> },
                        { path: 'language/add', element: <LanguageOperation /> },
                        { path: 'language/:id/edit', element: <LanguageOperation /> },
                        { path: 'language/:id/view', element: <LanguageOperation /> },
                        { path: 'categories', element: <Categories /> },
                        { path: 'categories/add', element: <CategoryOperation /> },
                        { path: 'categories/:id/edit', element: <CategoryOperation /> },
                        { path: 'categories/:id/view', element: <CategoryOperation /> },
                        { path: 'subscriptions', element: <Subscriptions /> },
                        { path: 'subscription/add', element: <SubscriptionOperation /> },
                        { path: 'subscription/:id/edit', element: <SubscriptionOperation /> },
                        { path: 'subscription/:id/view', element: <SubscriptionOperation /> },
                        { path: 'badges', element: <Badges /> },
                        { path: 'badge/add', element: <BadgeOperation /> },
                        { path: 'badge/:id/edit', element: <BadgeOperation /> },
                        { path: 'badge/:id/view', element: <BadgeOperation /> },
                        { path: 'grade-level-mappings', element: <GradeLevelMappings /> },
                        { path: 'grade-level-mapping/add', element: <GradeLevelMappingOperation /> },
                        { path: 'grade-level-mapping/:id/edit', element: <GradeLevelMappingOperation /> },
                        { path: 'grade-level-mapping/:id/view', element: <GradeLevelMappingOperation /> },
                        { path: 'assignments', element: <Assignments /> },
                        { path: 'assignment/add', element: <AssignmentOperation /> },
                        { path: 'assignment/:id/edit', element: <AssignmentOperation /> },
                        { path: 'assignment/:id/view', element: <AssignmentOperation /> },
                        { path: 'faqs', element: <Faqs /> },
                        { path: 'faq/add', element: <FaqOperation /> },
                        { path: 'faq/:id/edit', element: <FaqOperation /> },
                        { path: 'faq/:id/view', element: <FaqOperation /> },
                        { path: 'video-help', element: <VideoHelpList /> },
                        { path: 'video-help/add', element: <VideoHelpOperation /> },
                        { path: 'video-help/:id/edit', element: <VideoHelpOperation /> },
                        { path: 'video-help/:id/view', element: <VideoHelpOperation /> },
                        { path: 'coupons', element: <Coupons /> },
                        { path: 'coupons/sub/:createdAt', element: <SubCoupons /> },
                        { path: 'coupon/sub/:id/edit', element: <SubCouponOperation/> },
                        { path: 'coupon/add', element: <CouponOperation /> },
                        { path: 'coupon/:id/edit', element: <CouponOperation /> },
                        { path: 'coupon/:id/view', element: <CouponOperation /> },
                    ],
                },
                {
                    path: 'activity',
                    children: [
                        { path: 'exercise-levels', element: <ExerciseLevels /> },
                        { path: 'exercise-levels/:id/exercises', element: <Exercises /> },
                        { path: 'exercise-levels/:id/exercises/add', element: <ExerciseOperation /> },
                        { path: 'exercise-levels/:id/exercises/:exerciseid/edit', element: <ExerciseOperation /> },
                        { path: 'exercise-levels/:id/exercises/:exerciseid/view', element: <QuestionView /> },
                        { path: 'exercise-levels/:id/exercises/:exerciseid/questions', element: <ListQuestion /> },
                        { path: 'exercise-levels/:id/exercises/:exerciseid/:exerciseType/question/:questionid/edit',  element: <ListQuestionOperation />, },
                        { path: 'exercise-levels/:id/exercises/:exerciseid/:exerciseType/questions/add', element: <ListQuestionOperation />, },
                        { path: 'exercise-levels/:id/exercises/:exerciseid/question/:questionid/view', element: <QuestionView /> },
                        { path: 'student-score', element: <StudentScores />, },
                        { path: 'student-score/:id/view', element: <StudentScoreOperation />, },
                        { path: 'learning-levels', element: <LearningLevels />},
                        { path: 'learning-levels/:id/learnings',  element: <Learning />, },
                        { path: 'learning-levels/:id/learnings/add', element: <LearningOperation /> },
                        { path: 'learning-levels/:id/learnings/:learningid/edit', element: <LearningOperation /> },
                        { path: 'learning-levels/:id/learnings/:learningid/view', element: <LearningQuestionView /> },
                        { path: 'learning-levels/:id/learnings/:learningid/questions', element: <ListLearningQuestion /> },
                        { path: 'learning-levels/:id/learnings/:learningid/:exerciseType/question/:questionid/edit',  element: <ListLearningQuestionOperation />, },
                        { path: 'learning-levels/:id/learnings/:learningid/question/:questionid/view', element: <LearningQuestionView /> },
                        { path: 'learning-levels/:id/learnings/:learningid/:exerciseType/questions/add', element: <ListLearningQuestionOperation />, },
                    ],
                },
                {
                    path: '/',
                    children: [
                        { path: 'page-content', element: <PageContent /> },
                        { path: 'page-content/add', element: <PageContentOperation /> },
                        { path: 'page-content/:id/edit', element: <PageContentOperation /> },
                        { path: 'page-content/:id/view', element: <PageContentOperation /> },
                    ],
                },
                {
                    path: '/',
                    children: [
                        { path: 'global-settings', element: <GlobalSettings /> },
                        { path: 'global-settings/add', element: <GlobalSettingsOperation /> },
                        { path: 'global-settings/:id/edit', element: <GlobalSettingsOperation /> },
                        { path: 'global-settings/:id/view', element: <GlobalSettingsOperation /> },
                    ],
                },
                {
                    path: '/',
                    children: [
                        { path: 'support', element: <Supports /> },
                        { path: 'support/:id/view', element: <SupportOperation /> },
                        { path: 'support/:id/edit', element: <SupportOperation /> },
                    ],
                },
            ],
        },

        // Main Routes
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: 'coming-soon', element: <ComingSoon /> },
                { path: 'maintenance', element: <Maintenance /> },
                { path: '500', element: <Page500 /> },
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// User Management
const Roles = Loadable(lazy(() => import('../pages/user-management/Role/Roles')));
const RolePermission = Loadable(lazy(() => import('../pages/user-management/Role/RolePermission')));

// User Task
const UserTasks = Loadable(lazy(() => import('../pages/user-task/user-task/UserTasks')));
const UserTaskOperation = Loadable(lazy(() => import('../pages/user-task/user-task/UserTaskOperation')));

// Parent
const Parents = Loadable(lazy(() => import('../pages/subscription/Parent/Parents')));
const ParentOperation = Loadable(lazy(() => import('../pages/subscription/Parent/ParentOperation')));

// Student
const Students = Loadable(lazy(() => import('../pages/subscription/Student/Students')));
const StudentOperation = Loadable(lazy(() => import('../pages/subscription/Student/StudentOperation')));

// Subscription
const Transaction = Loadable(lazy(() => import('../pages/subscription/Transaction/Transactions')));
const TransactionOperation = Loadable(lazy(() => import('../pages/subscription/Transaction/TransactionOperation')));

// Roles
const RoleOperation = Loadable(lazy(() => import('../pages/user-management/Role/RoleOperation')));

// Employees
const Employees = Loadable(lazy(() => import('../pages/user-management/Employee/Employees')));
const EmployeeOperation = Loadable(lazy(() => import('../pages/user-management/Employee/EmployeeOperation')));

// User Approval
const UserApproval = Loadable(lazy(() => import('../pages/user-management/UserApproval/UserApproval')));
const UserApprovalOperation = Loadable(
    lazy(() => import('../pages/user-management/UserApproval/UserApprovalOperation'))
);

// Profile
const UserAccount = Loadable(lazy(() => import('../pages/profile/UserAccount')));

// Masters

// Grade
const Grades = Loadable(lazy(() => import('../pages/masters/Grade/Grades')));
const GradeOperation = Loadable(lazy(() => import('../pages/masters/Grade/GradeOperation')));

// Level
const Levels = Loadable(lazy(() => import('../pages/masters/Level/Levels')));
const LevelOperation = Loadable(lazy(() => import('../pages/masters/Level/LevelOperation')));

// Language
const Languages = Loadable(lazy(() => import('../pages/masters/Language/Languages')));
const LanguageOperation = Loadable(lazy(() => import('../pages/masters/Language/LanguageOperation')));

// Category
const Categories = Loadable(lazy(() => import('../pages/masters/Category/Categories')));
const CategoryOperation = Loadable(lazy(() => import('../pages/masters/Category/CategoryOperation')));

// Subscription
const Subscriptions = Loadable(lazy(() => import('../pages/masters/Subscription/Subscriptions')));
const SubscriptionOperation = Loadable(lazy(() => import('../pages/masters/Subscription/SubscriptionOperation')));

// Badge
const Badges = Loadable(lazy(() => import('../pages/masters/Badge/Badges')));
const BadgeOperation = Loadable(lazy(() => import('../pages/masters/Badge/BadgeOperation')));

// Grade Level Mappings
const GradeLevelMappings = Loadable(lazy(() => import('../pages/masters/GradeLevelMapping/GradeLevelMappings')));
const GradeLevelMappingOperation = Loadable(
    lazy(() => import('../pages/masters/GradeLevelMapping/GradeLevelMappingOperation'))
);

// Discount
const Coupons = Loadable(lazy(() => import('../pages/masters/CouponV2/Coupons')));
const CouponOperation = Loadable(lazy(() => import('../pages/masters/CouponV2/CouponOperation')));
const SubCoupons = Loadable(lazy(() => import('../pages/masters/CouponV2/SubCoupon/SubCoupons')));
const SubCouponOperation = Loadable(lazy(() => import('../pages/masters/CouponV2/SubCoupon/SubCouponOperation')));

// Assignments
const Assignments = Loadable(lazy(() => import('../pages/masters/Assignment/Assignments')));
const AssignmentOperation = Loadable(lazy(() => import('../pages/masters/Assignment/AssignmentOperation')));

// Exercise
const ExerciseLevels = Loadable(lazy(() => import('../pages/exercise/ExerciseLevels')));
const Exercises = Loadable(lazy(() => import('../pages/exercise/Exercises')));
const ExerciseOperation = Loadable(lazy(() => import('../pages/exercise/ExerciseOperation')));
const QuestionView = Loadable(lazy(() => import('../pages/exercise/QuestionView/QuestionView')));
const ListQuestion = Loadable(lazy(() => import('../pages/exercise/ListQuestion/ListQuestion')));
const ListQuestionOperation = Loadable(lazy(() => import('../pages/exercise/ListQuestion/ListQuestionOperation')));

// Learning
const LearningLevels = Loadable(lazy(() => import('../pages/learning/LearningLevels')));
const Learning = Loadable(lazy(() => import('../pages/learning/Learning')));
const LearningOperation = Loadable(lazy(() => import('../pages/learning/LearningOperation')));
const LearningQuestionView = Loadable(lazy(() => import('../pages/learning/QuestionView/QuestionView')));
const ListLearningQuestion = Loadable(lazy(() => import('../pages/learning/ListQuestion/ListQuestion')));
const ListLearningQuestionOperation = Loadable(lazy(() => import('../pages/learning/ListQuestion/ListQuestionOperation')));

// Student Score
const StudentScores = Loadable(lazy(() => import('../pages/exercise/StudentScore/StudetnScores')));
const StudentScoreOperation = Loadable(lazy(() => import('../pages/exercise/StudentScore/StudentScoreOperation')));

// FAQs
const Faqs = Loadable(lazy(() => import('../pages/masters/FAQ/FAQs')));
const FaqOperation = Loadable(lazy(() => import('../pages/masters/FAQ/FAQOperation')));

// Video-Help Section
const VideoHelpList = Loadable(lazy(() => import('../pages/masters/VideoHelp/VideoHelpList')));
const VideoHelpOperation = Loadable(lazy(() => import('../pages/masters/VideoHelp/VideoHelpOperation')));

// Page Content
const PageContent = Loadable(lazy(() => import('../pages/masters/PageContent/PageContents')));
const PageContentOperation = Loadable(lazy(() => import('../pages/masters/PageContent/PageContentOperation')));

// Global Settings
const GlobalSettings = Loadable(lazy(() => import('../pages/masters/GlobalSettings/GlobalSettings')));
const GlobalSettingsOperation = Loadable(lazy(() => import('../pages/masters/GlobalSettings/GlobalSettingsOperation')));

// Contact
const Supports = Loadable(lazy(() => import('../pages/Support/Supports')));
const SupportOperation = Loadable(lazy(() => import('../pages/Support/SupportOperation')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
